import request from "../utils/https";

/**
 * 获取叶子模块下的题组列表 - 用于机房考试模块
 * @param data
 * @returns {*}
 */
export function getListPaperGroup(data = {}) {
    return request({
        url: '/apiv2/teacher/exam/listPaperGroup',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}