<template>
  <nav class="tabs">
    <ul :class="['tabs-content', type === 2 ? 'tabs-bar' : type === 3 ? 'tabs-radio' : '']">
      <li :class="[index === idx ? 'active' : '']" v-for="(item, idx) in list" :key="item.id" @click="tabChange(idx)">
        <el-tooltip :content="item.name" placement="bottom" effect="light">
          <div class="name">{{item.name}}</div>
        </el-tooltip>
        <div class="bar" v-if="type === 2"></div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    list: Array,
    type: Number,
    index: Number
  },
  methods: {
    tabChange (index) {
      this.$emit('tabChange', index)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabs {
    background-color: #fff;
    padding-left: 28px;
    .tabs-content {
      @include flex(flex-start);
      overflow: hidden;
      width: 100%;
      height: 54px;
      color: #999999;
      border-bottom: 1px solid #F1F1F1;
      li {
        @include flex;
        flex-shrink: 1;
        overflow: hidden;
        height: 54px;
        margin-right: 28px;
        cursor: pointer;
        &:hover {
          color: #333333;
        }
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .active {
        color: #333333;
        font-weight: bold;
      }
    }
    .tabs-bar {
      border: none;
      font-size: 15px;
      li {
        position: relative;
        .bar {
          width: 12px;
          height: 4px;
          border-radius: 2px;
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .active {
        color: #333333;
        font-weight: bold;
        .bar {
          background-color: #309AF2;
        }
      }
    }
    .tabs-radio {
      flex-wrap: wrap;
      height: 100%;
      border: none;
      font-size: 14px;
      li {
        height: 28px;
        line-height: 28px;
        text-align: center;
        padding: 0 14px;
        border: 1px solid #E5E5E5;
        border-radius: 17px;
        margin-right: 14px;
        margin-top: 13px;
        margin-bottom: 10px;
        &:hover {
          background-color: #F6F6F6;
        }
      }
      .active {
        background-color: #EAF4FD;
        color: #309AF2;
        font-weight: bold;
        border-color: #EAF4FD;
      }
    }
  }
</style>
