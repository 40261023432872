<template>
  <el-dialog class="selector-dialog" title="学校切换" :visible.sync="visible" width="500px">
    <div>
      <el-select class="selector" v-model="schoolId" filterable placeholder="输入学校名称搜索">
        <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName" :value="item.schoolId" />
      </el-select>
    </div>
    <div class="footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button class="ml-28" type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ChangeSchoolDialog",
  props: {
    schoolList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      visible: false,
      schoolId: ''
    }
  },
  methods: {
    show(schoolId) {
      this.visible = true
      this.schoolId = schoolId
    },
    closeDialog() {
      this.visible = false
      this.schoolId = ''
    },
    confirm() {
      sessionStorage.setItem('schoolIdForCreateExam', this.schoolId)
      this.$emit('change', this.schoolId)
      this.closeDialog()
    }
  }
}
</script>

<style scoped lang="scss">
.selector {
  width: 100%;
}

.footer {
  margin-top: 40px;
  text-align: center;
}
</style>